import React from "react";

const GenericError = ({ errorBoundaryError }) => {
  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <h1>Something went wrong</h1>
      <p>{errorBoundaryError?.message || "An unknown error occurred."}</p>
      <p>Please try refreshing the page or contact support.</p>
    </div>
  );
};

export default GenericError;
