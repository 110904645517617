import React, { useState } from "react";
import HorizontalHomepageMenu from "redesign/components/HorizontalHomepageMenu";
import BrandLogo from "redesign/components/BrandLogo";
import CartButton from "redesign/components/CartIconButton";
import ProfileMenuButton from "redesign/components/ProfileMenuButton";
import "./Header.scss";

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div>
      <header className="fixed top-0 w-full bg-brand z-50 shadow-md border-b border-white/10">
        <div className="max-w-[1440px] mx-auto p-6 flex justify-between items-center gap-6">
          {/* Hamburger Icon - Visible on small screens */}
          <div className="flex md:hidden">
            <button
              onClick={toggleMobileMenu}
              className="p-2 text-white focus:outline-none"
              aria-label="Toggle Menu"
            >
              MENU
            </button>
          </div>

          {/* Brand Logo */}
          <div className="flex justify-center md:justify-start">
            <BrandLogo />
          </div>

          <div className="hidden md:flex md:flex-row flex-1 flex-nowrap justify-center items-center gap-6 mx-10">
            {/* First Input */}
            <div className="relative w-full lg:w-3/5">
              <input
                type="text"
                className="w-full p-3 text-black bg-white rounded-lg placeholder-gray-400 focus:outline-none"
                placeholder="Search by product name, SKU, Manufacturer or Vendors"
              />
            </div>

            {/* Second Input */}
            <div className="w-full lg:w-2/5">
              <input
                type="text"
                className="w-full p-3 text-black bg-white rounded-lg placeholder-gray-400 focus:outline-none"
                placeholder="Change Practice"
              />
            </div>
          </div>

          <div className="flex justify-end items-center gap-10">
            <CartButton itemCount={10} />
            <ProfileMenuButton />
          </div>
        </div>
      </header>
      <div className="flex lg:hidden">Here</div>
      {/* Display Horizontal Menu Desktop Only */}
      <div className="hidden md:flex">
        <HorizontalHomepageMenu />
      </div>
      <div className="flex lg:hidden">Here</div>
    </div>
  );
};

export default Header;
