import React, { useState, useEffect } from "react";
import { logErrorToSplunk } from "legacy/services/splunkLogger";
import GenericError from "./GenericError";

const ErrorBoundary = ({ children }) => {
  const [error, setError] = useState(null);

  const handleError = (err) => {
    setError(err);

    logErrorToSplunk({
      type: "FRONTEND_ERROR",
      message: err.message,
      stack: err.stack,
      userAgent: navigator.userAgent,
      timestamp: new Date().toISOString(),
    });
  };

  useEffect(() => {
    const handleGlobalError = (event) => {
      handleError(event.error || new Error(event.message));
    };

    const handlePromiseRejection = (event) => {
      handleError(event.reason || new Error("Unhandled promise rejection"));
    };

    window.addEventListener("error", handleGlobalError);
    window.addEventListener("unhandledrejection", handlePromiseRejection);

    return () => {
      window.removeEventListener("error", handleGlobalError);
      window.removeEventListener("unhandledrejection", handlePromiseRejection);
    };
  }, []);

  if (error) {
    return <GenericError errorBoundaryError={error} />;
  }

  return <>{children}</>;
};

export default ErrorBoundary;
