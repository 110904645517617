import { local } from "constant";

const splunkApiUrl = `${local.baseUrl}frontend-error`;

export const logErrorToSplunk = async (payload) => {
  try {
    const response = await fetch(splunkApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      console.error("Failed to send log to Splunk", response.statusText);
    }
  } catch (error) {
    console.error("Error sending log to Splunk:", error);
  }
};
