import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import { Router, Switch, Route } from "react-router-dom";
import history from "./history";
import { configureStore } from "legacy/store/index";
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";
import ErrorBoundary from "legacy/components/Error/ErrorBoundary";
// store
export const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <Router history={history}>
        <Switch>
          <Route
            path="/"
            render={(props) => (
              <App {...props} location={props.location} history={history} />
            )}
          />
        </Switch>
      </Router>
    </ErrorBoundary>
  </Provider>,
  document.getElementById("root"),
);

// registerServiceWorker();
unregisterServiceWorker();
