import { local } from "constant";
import React, { useState } from "react";

/**
 * ImageWithFallback Component
 * Renders an image and falls back to initials if the image is missing or fails to load.
 *
 * @param {string} src - The image source URL.
 * @param {string} alt - The alt text for the image.
 * @param {string} name - The name to extract initials from.
 * @param {string} className - Additional CSS classes for styling.
 */
const ImageWithFallback = ({ src, alt, name = "", className = "" }) => {
  const [hasError, setHasError] = useState(false);

  // Function to extract the first initial of the name
  const getInitial = () => (name ? name.charAt(0).toUpperCase() : "");

  return (
    <div
      className={`flex items-center justify-center rounded-full bg-gray-300 text-gray-700 font-semibold ${className}`}
    >
      {hasError || !src ? (
        <span>{getInitial()}</span>
      ) : (
        <img
          src={`${local.imageUrl}${src}`}
          alt={alt || "image"}
          className="w-full h-full object-cover rounded-full"
          onError={() => setHasError(true)} // Set error state on load failure
        />
      )}
    </div>
  );
};

export default ImageWithFallback;
