import { configureStore as configureRTKStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

// Legacy Reducers
import legacyReducers from "legacy/reducers";

// Slices RTK
import homepageSlice from "redesign/redux/slices/homepageSlice";
import companySlice from "redesign/redux/slices/companySlice";
import customerSlice from "redesign/redux/slices/customerSlice";
import headerMenuSlice from "redesign/redux/slices/headerMenuSlice";

import { createLogger } from "redux-logger";

const loggerMiddleware = createLogger();

const rootReducer = combineReducers({
  ...legacyReducers, // Includes legacy reducers to maintain existing functionality while integrating new slices.
  homepage: homepageSlice, // Adds the new homepage slice for the redesign feature.
  company: companySlice,
  customer: customerSlice,
  menu: headerMenuSlice,
});

export function configureStore() {
  return configureRTKStore({
    reducer: rootReducer, // Combines all reducers, both legacy and new, into a single root reducer.
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ thunk: true }) // Ensures thunk is included for async actions.
        .concat(loggerMiddleware), // Adds logger middleware for debugging during development.
    devTools: process.env.NODE_ENV !== "production", // Enables Redux DevTools only in non-production environments.
  });
}
