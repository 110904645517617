import { local } from "constant";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchActiveCompany,
  resetActiveCompany,
} from "redesign/redux/slices/companySlice";
import {
  fetchCustomer,
  resetCustomer,
} from "redesign/redux/slices/customerSlice";
import ImageWithFallback from "redesign/components/ImageWithFallback";

const ProfileMenuButton = () => {
  const dispatch = useDispatch();

  const {
    data: companyData,
    loading: companyLoading,
    error: companyError,
  } = useSelector((state) => state.company);

  const {
    data: customerData,
    loading: customerLoading,
    error: customerError,
  } = useSelector((state) => state.customer);

  const role = useSelector((state) => state.ecommerce.role);

  useEffect(() => {
    dispatch(fetchActiveCompany());
    dispatch(fetchCustomer());

    return () => {
      dispatch(resetActiveCompany());
      dispatch(resetCustomer());
    };
  }, [dispatch]);

  if (companyLoading || customerLoading) return <div>Loading...</div>;
  if (companyError || customerError)
    return <div>Error: {companyError || customerError}</div>;

  return (
    <div className="flex items-center gap-2 cursor-pointer">
      <span className="hidden md:block text-white text-sm font-semibold">
        {companyData?.name}
      </span>
      <div className="w-10 h-10 rounded-full bg-white overflow-hidden">
        <ImageWithFallback
          src={customerData?.profilePic} // Image URL
          alt="Customer profile"
          name={customerData?.name} // Fallback name for initials
          className="w-10 h-10"
        />
      </div>
    </div>
  );
};

export default ProfileMenuButton;
