/**
 * header search widget
 */
import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import Autosuggest from 'react-autosuggest';
import { local } from "constant";
import { addProductItem, showAlert, addToWishlist, logError } from "../../../actions/action";
import { connect } from 'react-redux';
import { getAccessToken, getAdditionalHeaders } from "../../../../Authorization";
import loader from '../../../assets/loader.gif';
import "./headers.css";
import "./searchBox.css";
var suggestionTimeout = null;

class SearchBox extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  isSearchFormOpen: false,
                  suggestions: [],
                  pageDetails: {},
                  value: "",
                  selectedProduct: {},
                  id: "",
                  searchValue: "",
                  isRecentSearch: true,
                  loading: false,
                  showSuggestions: true
            };
      }

      handleSearchForm(isOpen) {
            this.setState({
                  isSearchFormOpen: isOpen, suggestions: [],
                  //  value: ""
            });
      };


      onSuggestionsFetchRequested = (e) => {
            this.setState({ loading: false });
            if (e.reason === "input-focused") {
                  this.setState({ isRecentSearch: true }, () => {
                        this.getRecentSearches();
                  })
            }
            if (e.reason === "input-changed") {
                  if (suggestionTimeout) {
                        clearTimeout(suggestionTimeout);
                  }
                  if (e.value.length == 0) {
                        this.setState({ isRecentSearch: true }, () => {
                              this.getRecentSearches();
                        })
                  }
                  if (e.value.length > 1) {
                        this.setState({ loading: true }, () => {
                              suggestionTimeout = setTimeout(() => {
                                    this.setState({ isRecentSearch: false }, () => {
                                          this.getSuggestions(e.value);
                                    })
                              }, 500);
                        });
                  } else {
                        this.setState({ loading: false });
                  }
            }
      };


      onChange = (event, { newValue }) => {
            if (newValue.includes('-m-')) {
                  let arr = newValue.split('-m-');
                  newValue = arr.join('');
            }
            if (newValue.includes('-v-')) {
                  let arr = newValue.split('-v-');
                  newValue = arr.join('');
            }
            this.setState({ value: newValue, searchValue: newValue, showSuggestions: true });
            this.props.onSearchChange(newValue)
      };

      async getRecentSearches() {
            var sessionData = getAccessToken();
            await fetch(local.baseUrl + `api/v3/search/recent`, {
                  method: "get",
                  headers: new Headers({
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "bearer " + sessionData,
                  ...getAdditionalHeaders()
                  })
            })
                  .then(response => {
                        if (response.status === 200) {
                              return response.json();
                        } else {
                              this.props.logError(response);
                              return;
                        }
                  })
                  .then(data => {
                        if (data) {
                              let list = [];
                              let keywords = {
                                    title: "",
                                    languages: data && data.length > 0 ? data : []
                              }
                              list.push(keywords);
                              this.setState({ suggestions: list });
                        }
                  })
                  .catch(error =>
                        this.setState({
                              error
                        })
                  );
      }

      getSuggestions(val) {
            var searchPayload = {
                  query: val,
            }
            var sessionData = getAccessToken();
            fetch(local.baseUrl + `api/v1/search/autocomplete`, {
                  method: "post",
                  body: JSON.stringify(searchPayload),
                  headers: new Headers({
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "bearer " + sessionData,
                  ...getAdditionalHeaders()
                  })
            })
                  .then(response => {
                        if (response.status === 200) {
                              return response.json();
                        } else {
                              this.props.logError(response);
                              return;
                        }
                  })
                  .then(data => {
                        if (data && this.state.showSuggestions) {
                              this.setMultipleSectionFormat(data);
                        } else {
                              this.setState({ suggestions: [] })
                        }
                        this.setState({ loading: false })
                  })
                  .catch(error =>
                        this.setState({
                              error
                        })
                  );
      }

      setMultipleSectionFormat = (data) => {
            let list = [];
            if (data.product && data.product.length > 0) {
                  let productObj = {
                        title: "Products",
                        languages: data.product
                  }
                  list.push(productObj);
            }
            if (data.manufacturer && data.manufacturer.length > 0) {
                  let manufacturerObj = {
                        title: "Manufacturers",
                        languages: this.addTitle(data.manufacturer, '-m-')
                  }
                  list.push(manufacturerObj);
            }
            if (data.store && data.store.length > 0) {
                  let storeObj = {
                        title: "Vendors",
                        languages: this.addTitle(data.store, '-v-')
                  }
                  list.push(storeObj);
            }
            this.setState({ suggestions: list });
      }

      addTitle = (list, prefix) => {
            list.map((val, i) => {
                  val = prefix + val
                  list[i] = val;
            })
            return list;
      }

      onSuggestionsClearRequested = (e) => {
            this.setState({
                  suggestions: [],
                  // value: "",
                  isRecentSearch: true, loading: false
            });
      };

      redirectToResult = (suggestion, isManufacturer, isVendor) => {
            let isResultPage = this.props.location.pathname.startsWith('/results');
            suggestion = this.checkForCharacters(suggestion);
            this.setState({
                  suggestions: [],
                  value: suggestion,
                  isRecentSearch: true
            }, () => {
                  if (isResultPage) {
                        this.props.history.replace({ pathname: `/results/${encodeURI(suggestion)}${isManufacturer ? '?manufacturer=true' : isVendor ? '?vendor=true' : ''}` })
                  } else {
                        this.props.history.push(`/results/${encodeURI(suggestion)}${isManufacturer ? '?manufacturer=true' : isVendor ? '?vendor=true' : ''}`);
                  }
            });

      }

      search = () => {
            let { searchValue } = this.state;
            this.setState({ showSuggestions: false, suggestions: [], isRecentSearch: true }, () => {
                  if (searchValue && searchValue.length > 1) {
                        let isResultPage = this.props.location.pathname.startsWith('/results');
                        searchValue = this.checkForCharacters(searchValue);
                        if (isResultPage) {
                              this.props.history.replace({ pathname: `/results/${encodeURI(searchValue)}` });
                        } else {
                              this.props.history.push(`/results/${encodeURI(searchValue)}`);
                        }
                        this.setState({
                              //  value: "" ,
                              searchValue: ""
                        });
                  }
            });

      }

      checkForCharacters = (suggestion) => {
            if (suggestion.includes('\"')) {
                  let arr = suggestion.split('\"');
                  suggestion = arr.join('');
            }
            if (suggestion.includes('/')) {
                  suggestion = suggestion.replaceAll('/', '_');
            }
            return suggestion;
      }



      renderSearchSuggestion = (suggestion) => {
            let value = suggestion;
            let isManufacturer = false;
            let isVendor = false;
            if (value.includes('-m-')) {
                  isManufacturer = true;
                  let arr = suggestion.split('-m-');
                  suggestion = arr.join('');
            }
            if (value.includes('-v-')) {
                  isVendor = true;
                  let arr = suggestion.split('-v-');
                  suggestion = arr.join('');
            }
            return <div className="searched-product" onClick={() => this.redirectToResult(suggestion, isManufacturer, isVendor)} style={{ cursor: "pointer" }}>
                  <div>
                        <ul className="w-full d-flex justify-content-between product-list">
                              <li className="column-val p-10" >
                                    <a>
                                          {suggestion}
                                    </a>
                              </li>
                        </ul>
                  </div>
            </div >
      };

      renderSearchSuggestionsContainer = ({ containerProps, children, query }) => {
            const { isRecentSearch } = this.state;
            return children ?
                  <div {...containerProps} className="suggestionContainer">
                        <div className="d-flex justify-content-between rcnt-title p-10">
                              <label className="column-head">{isRecentSearch ? "My Recent Searches:" : "Search Suggestions:"} </label>
                        </div>

                        <div className="my-title">
                              {children}
                        </div>
                  </div >
                  : <div></div>

      };

      renderSectionTitle = (section) => {
            return (
                  <div className="my-title ml-10">
                        <h6 style={{ color: "black", margin: 'auto', textDecoration: 'underline' }}>{section.title}</h6>
                  </div>
            );
      }

      searchKeyword = (e) => {
            e.preventDefault();
            this.search();
      }
      getSectionSuggestions = (section) => {
            return section.languages;
      }

      getSuggestionValue = (suggestion) => {
            return suggestion;
      }

      render() {
            const support = JSON.parse(sessionStorage.getItem("support"));
            const { suggestions, loading } = this.state;
            const inputProps = {
                  placeholder: 'Product Name, SKU, Manufacturer, Vendor',
                  value: this.props.searchStr,
                  onChange: this.onChange,
                  id: 'input-autosuggest'
            };
            return (
                  <div className="search-form justify-content-between" style={{ background: "transparent" }} >
                        <form onSubmit={(e) => { this.searchKeyword(e) }}>
                              <div className={`${support ? 'support-input-wrap' : 'input-wrap'}  main-search`}>
                                    <Autosuggest
                                          // focusInputOnSuggestionClick
                                          multiSection={true}
                                          suggestions={suggestions}
                                          onSuggestionsFetchRequested={(e) => { this.onSuggestionsFetchRequested(e) }}
                                          onSuggestionsClearRequested={(e) => { this.onSuggestionsClearRequested(e) }}
                                          getSuggestionValue={this.getSuggestionValue}
                                          renderSuggestion={this.renderSearchSuggestion}
                                          renderSuggestionsContainer={this.renderSearchSuggestionsContainer}
                                          renderSectionTitle={this.renderSectionTitle}
                                          getSectionSuggestions={this.getSectionSuggestions}
                                          shouldRenderSuggestions={() => true}
                                          inputProps={inputProps}
                                          required
                                    />
                              </div>
                              {loading ? <img alt="loader" style={{ height: '30px' }} className="loading-gif"
                                    src={loader} /> : ""}
                              <IconButton
								className="search-btn"
								onClick={() => { this.search() }}
								style={{ position: 'relative', right: '34px' }}
							  >
                                    <i className="material-icons">search</i>
                              </IconButton>
                        </form>
                  </div>
            );
      }
}


const mapStateToProps = ({ ecommerce }) => {
      const { cart } = ecommerce;
      return { cart };
}
export default connect(mapStateToProps, { addProductItem, addToWishlist, showAlert, logError })(SearchBox);
