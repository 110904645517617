import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchRequest } from "redesign/util/fetchRequest";
import { getAccessToken, getAdditionalHeaders } from "Authorization";

/**
 * Async thunk to fetch menu data.
 * Constructs the appropriate URL based on the current domain or admin path.
 */
export const fetchMenuData = createAsyncThunk(
  "menu/fetchMenuData",
  async (_, { rejectWithValue }) => {
    try {
      const sessionData = getAccessToken();
      if (!sessionData) throw new Error("Session data is missing.");

      // Determine the domain or admin URL
      const urlParam = window.location.pathname?.includes("admin")
        ? "default"
        : window.location.host;

      const domainUrlParam = `&domainUrl=${urlParam}`;

      // Perform the API request
      const data = await fetchRequest(`api/v3/menu/active?${domainUrlParam}`, {
        method: "GET",
        headers: {
          ...getAdditionalHeaders(),
        },
      });

      if (!data || !data.menuDatas) {
        throw new Error("No menu data found.");
      }

      return data.menuDatas; // Return the menu data
    } catch (error) {
      console.error("Error fetching menu data:", error.message);
      return rejectWithValue(error.message);
    }
  },
);

/**
 * Menu slice with initial state, reducers, and extraReducers.
 */
const headerMenuSlice = createSlice({
  name: "menu",
  initialState: {
    links: [], // Stores menu links
    loading: false, // Indicates loading state
    error: null, // Stores error message
  },
  reducers: {
    resetMenu: (state) => {
      state.links = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenuData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMenuData.fulfilled, (state, action) => {
        state.loading = false;
        state.links = action.payload; // Store menu data
      })
      .addCase(fetchMenuData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store error message
      });
  },
});

export const { resetMenu } = headerMenuSlice.actions;
export default headerMenuSlice.reducer;
