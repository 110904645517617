import React from "react";
import SectionBanner from "redesign/components/SectionBanner";
import SectionInformation from "redesign/components/SectionInformation";
import "./index.scss";

const Homepage = () => {
  return (
    <div className="homepage-wrapper pt-4 sm:pt-4 md:pt-28 lg:pt-40">
      <SectionBanner />
      <SectionInformation />
    </div>
  );
};

export default Homepage;
