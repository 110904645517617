import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchRequest } from "redesign/util/fetchRequest";

export const fetchActiveCompany = createAsyncThunk(
  "company/fetchActiveCompany",
  async (_, { rejectWithValue }) => {
    try {
      const companyId = sessionStorage.getItem("companyId");
      if (!companyId || companyId === "null") {
        throw new Error("Company ID is invalid or null.");
      }
      const data = await fetchRequest(
        `/api/v1/company/find-by-id?id=${companyId}`,
        {
          method: "GET",
        },
      );

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const activeCompanySlice = createSlice({
  name: "activeCompany",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetActiveCompany: (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActiveCompany.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchActiveCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchActiveCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetActiveCompany } = activeCompanySlice.actions;
export default activeCompanySlice.reducer;
