import React from "react";
import LogoSVG from "redesign/assets/vetscout.svg";

const BrandLogo = ({ size = "md" }) => {
  const sizeClasses = {
    sm: "w-18 h-auto",
    md: "w-[110px] h-auto",
    lg: "w-32 h-auto",
  };

  return (
    <div className={`inline-block ${sizeClasses[size] || sizeClasses.md}`}>
      <img src={LogoSVG} alt="Logo" className="w-full h-full object-contain" />
    </div>
  );
};

export default BrandLogo;
