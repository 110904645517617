import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMenuData,
  resetMenu,
} from "redesign/redux/slices/headerMenuSlice";

const HorizontalHomepageMenu = () => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(true);

  const { links, loading, error } = useSelector((state) => state.menu);

  // Handle menu visibility on scroll
  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setIsVisible(false); // Hide menu on scroll down
      } else {
        setIsVisible(true); // Show menu on scroll up
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Fetch menu data on mount
  useEffect(() => {
    dispatch(fetchMenuData());

    return () => {
      dispatch(resetMenu());
    };
  }, [dispatch]);

  // Handle loading and error states
  if (loading) {
    return <div>Loading menu...</div>;
  }

  if (error) {
    return <div>Error loading menu: {error}</div>;
  }

  return (
    <nav
      className={`fixed top-20 left-0 w-full bg-brand text-white shadow-md transition-transform duration-300 z-40 ${
        isVisible ? "translate-y-0" : "-translate-y-full"
      }`}
    >
      <div className="max-w-[1440px] mx-auto px-4 md:h-20 overflow-visible">
        <ul className="menu flex justify-center items-center gap-4 md:gap-8 md:h-full overflow-visible pt-4">
          {links.map((link, index) => (
            <MenuItem key={index} item={link} />
          ))}
        </ul>
      </div>
    </nav>
  );
};

const MenuItem = ({ item }) => {
  const { menu_title, path, child_routes } = item;

  return (
    <li className="relative group">
      {/* Main menu item */}
      <a
        href={path}
        className="font-semibold hover:text-blue-300 px-3 py-2 rounded-lg transition"
      >
        {menu_title}
      </a>

      {/* Submenu */}
      {child_routes?.length > 0 && (
        <ul
          className="absolute top-full left-0 bg-gray-800 text-white rounded-lg shadow-lg hidden group-hover:flex flex-col z-50"
          style={{ minWidth: "200px" }}
        >
          {child_routes.map((subItem, index) => (
            <li
              key={index}
              className="hover:bg-gray-700 px-4 py-2 rounded-md whitespace-nowrap"
            >
              <a
                href={`${path}${subItem.path}`} // Concatenate parent path with child path
                className="text-white no-underline"
              >
                {subItem.menu_title}
              </a>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};


export default HorizontalHomepageMenu;
