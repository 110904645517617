import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchRequest } from "redesign/util/fetchRequest";

/**
 * Async thunk to fetch customer data.
 * Fetches customer details and updates roles in sessionStorage if groups exist.
 */
export const fetchCustomer = createAsyncThunk(
  "customer/fetchCustomer",
  async (_, { rejectWithValue }) => {
    try {
      // Make the API request
      const data = await fetchRequest("api/v3/customers", { method: "GET" });

      // If groups exist, save roles to sessionStorage
      if (data?.groups?.length > 0) {
        const roles = data.groups.map((group) => group.groupType);
        sessionStorage.setItem("roles", JSON.stringify(roles));
      }

      return data; // Return the fetched customer data
    } catch (error) {
      console.error("Error fetching customer:", error.message);
      return rejectWithValue(error.message);
    }
  },
);

/**
 * Customer slice with initial state, reducers, and extraReducers.
 */
const customerSlice = createSlice({
  name: "customer",
  initialState: {
    data: null, // Stores customer details
    loading: false, // Indicates loading state
    error: null, // Stores error message
  },
  reducers: {
    /**
     * Resets the customer state and removes roles from sessionStorage.
     */
    resetCustomer: (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
      sessionStorage.removeItem("roles");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload; // Updates customer data
      })
      .addCase(fetchCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Updates error message
      });
  },
});

export const { resetCustomer } = customerSlice.actions;
export default customerSlice.reducer;
