import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CartIcon from "redesign/assets/cart-icon.svg";

const CartButton = () => {
  const history = useHistory();

  const { cartItemsCount, cart } = useSelector((state) => state.ecommerce);

  const goToCart = () => {
    history.push("/cart");
  };

  return (
    <button
      onClick={goToCart}
      className="relative p-2 bg-transparent border-none cursor-pointer"
    >
      <img src={CartIcon} alt="Cart" className="w-6 h-6 object-contain" />

      {cartItemsCount > 0 && (
        <span className="absolute -top-0 -right-0 bg-red-500 text-white text-xs w-[18px] h-[18px] flex items-center justify-center rounded-full">
          {cartItemsCount}
        </span>
      )}
    </button>
  );
};

export default CartButton;
