
import React, { Fragment } from 'react';
import Cart from "./Cart";
import Hyperwallet from "./Hyperwallet";
import Notification from "./Notification";
import UserLogout from "../userLogout/userLogout";
import "./HeaderOne.css";
import { getProfile, getActiveCompany } from "../../../actions/action";
import { connect } from 'react-redux';
import { HtmlTooltip } from '../../global/html-tool-tip';

class HeaderDiv extends React.Component {
    state = {
        company: {},
        hyperwalletBalance: null
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.activeCompany !== this.props.activeCompany) {
            this.setState({ company: nextProps.activeCompany });
        }
    }



    render() {
        const { company } = this.state;
        const { groupAreaManager } = this.props;
        return (
            <div>
              {console.log(groupAreaManager)}
                {groupAreaManager != null  ?
                    groupAreaManager ?
                        <div className="d-flex justify-content-center">
                            <UserLogout location={this.props.location} groupAreaManager={groupAreaManager} />
                        </div>
                        :
                        <div className="d-flex">
                            <UserLogout location={this.props.location} />
                            <Notification />
                            <Cart />
                        </div>
                    : ""
                }
                <div className="hsptl-name" style={{ textAlign: 'center' }}>
                    <HtmlTooltip maxWidth={300} title={company && company.name ? company.name : ""}>
                        <span>{company && company.name ? company.name.length > 20 ? company.name.substring(0, 20) + '..' : company.name : ''}</span>
                    </HtmlTooltip>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ ecommerce }) => {
    const { activeCompany } = ecommerce;
    return { activeCompany };
}

export default connect(mapStateToProps, { getProfile, getActiveCompany })(HeaderDiv);
