import { getAccessToken, getAdditionalHeaders } from "Authorization";
import { local } from "constant";

/**
 * Generic fetch request function using the native Fetch API.
 * Ensures proper URL construction and includes required headers such as authorization.
 *
 * @param {string} url - The API endpoint (relative path, e.g., "/api/v1/example").
 * @param {Object} options - Additional fetch options (method, headers, body).
 * @returns {Promise<Object>} - Returns the parsed JSON response.
 */
export const fetchRequest = async (url, options = {}) => {
  // Retrieve session token for authorization
  const sessionData = getAccessToken();

  if (!sessionData) {
    throw new Error("Session data is invalid or missing.");
  }

  // Define default headers for the request
  const defaultHeaders = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": `Bearer ${sessionData}`,
    ...getAdditionalHeaders(),
  };

  // Ensure base URL is properly set, fallback to "/"
  const baseUrl = local.baseUrl || "/";

  /**
   * Construct the full request URL:
   * - new URL ensures proper concatenation of baseUrl and endpoint.
   * - Uses window.location.origin as the root if baseUrl is "/".
   */
  const fullUrl = new URL(url, window.location.origin + baseUrl).toString();

  try {
    console.log(`Requesting URL: ${fullUrl}`);

    // Perform the fetch request
    const response = await fetch(fullUrl, {
      method: options.method || "GET", // Default method is GET
      headers: {
        ...defaultHeaders, // Include default headers
        ...options.headers, // Allow custom headers to override defaults
      },
      body: options.body ? JSON.stringify(options.body) : null, // Convert body to JSON if provided
    });

    // Check for non-successful HTTP response status
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Parse and return the JSON response
    const data = await response.json();
    return data;
  } catch (error) {
    // Log and rethrow the error for handling by the caller
    console.error("Fetch Error:", error.message);
    throw error;
  }
};
